import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';
import { Note } from 'core/types';
import { Icon, IconOption } from '../Icon';
import CardMenuDropdown from './CardMenuDropdown';

const ButtonContainer = styled.div`
  position: relative;
  display: inline-block;
`;

const IconButton = styled.button`
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

interface CardMenuButtonProps {
  note: Note;
}

const CardMenuButton: React.FC<CardMenuButtonProps> = ({ note }) => {
  const [isOpen, setIsOpen] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent): void => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (buttonRef.current && !buttonRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <ButtonContainer ref={buttonRef}>
      <IconButton onClick={handleClick}>
        <Icon type={IconOption.Dots} size="16px" />
      </IconButton>
      {isOpen && <CardMenuDropdown note={note} />}
    </ButtonContainer>
  );
};

export default CardMenuButton;
