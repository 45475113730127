import { v4 as uuidv4 } from 'uuid';
import { Note, UUID, TypeOfNote, NoteSummary, SearchNote } from 'core/types';

export const createUUID = (): UUID => uuidv4();

export const createNote = (title: string, type: TypeOfNote, value?: string, createdFromId?: UUID): Note => {
  return {
    id: createUUID(),
    type,
    title,
    value: value || '',
    createdFromId,
    createdAt: new Date().toISOString(),
    backlinks: createdFromId ? [createdFromId] : [],
    links: [],
  } as Note;
};

export const summarizeNote = (note: Note | SearchNote): NoteSummary => {
  return {
    id: note.id,
    title: note.title,
    type: note.type,
  };
};
