import React, { useEffect, useState, useContext } from 'react';
// This surface lays out the notes passed to it in shownIds in a tree-like structure

import styled from 'styled-components';
import { UUID, isAnnote, isSource, Note, Source } from 'core/types';
import { ShownIdsContext } from 'src/context/ShownContext';
import { CardDropzone } from 'src/components/Cards/CardDropzone';
import { CardSpacer } from 'src/routes/Surfaces/Tree/_components/CardSpacer';
import { Card } from 'src/components/Cards/Card';

interface LinkMap {
  [key: UUID]: Note[];
}

const Surface = styled.div`
  position: relative;
  display: flex;
  flex-direction: row; /* Change to row for horizontal layout */
  gap: 24px;
  padding: 10rem 20rem 10rem 10rem;
  pointer-events: auto;
  overflow-x: auto; /* Allow horizontal scrolling if needed */
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 37.5rem; /* Set a minimum width for each column */
  max-width: 37.5rem; /* Set a maximum width for each column */
  padding-left: 2rem;
`;

const CardWrapper = styled.div`
  position: relative;
  height: fit-content;

  &:hover {
    z-index: 1001;
  }
`;

const SourceColumn: React.FC<{ source: Source; shownBacklinks: Note[] }> = ({ source, shownBacklinks }) => {
  // const shownBackLinkIds = shownBacklinks.map((n) => n.id);
  // const hiddenBackLinks = source.backlinks.filter((bid) => !shownBackLinkIds.includes(bid));

  return (
    <div>
      <CardWrapper>
        <Card key={source.id} note={source} />
      </CardWrapper>

      <CardSpacer linkTo={source} afterId={source.id} />
      <CardDropzone linkTo={source} afterId={source.id} />

      {shownBacklinks.map((blNote) => (
        <>
          <Card key={blNote.id} note={blNote} />
          <CardSpacer linkTo={source} afterId={blNote.id} />
          <CardDropzone linkTo={source} afterId={blNote.id} />
        </>
      ))}
    </div>
  );
};

const SourceGridSurface: React.FC = () => {
  const { shownIds, showAafterB, surfaceNoteMap } = useContext(ShownIdsContext);

  const [sources, setSources] = useState<Source[]>([]);
  const [shownBacklinksMap, setShownBacklinksMap] = useState<LinkMap>({});

  useEffect(() => {
    const shownSources = shownIds.map((id) => surfaceNoteMap[id]).filter(isSource);
    const newSources = shownSources.filter((s) => !sources.includes(s));

    // By default show all backlinks of each new source
    const newBacklinks = newSources.flatMap((s) => surfaceNoteMap[s.id]?.backlinks || []);
    const uniqueNewBacklinks = Array.from(new Set([...newBacklinks, ...shownIds]));
    showAafterB(uniqueNewBacklinks, shownIds[shownIds.length - 1]);
    setSources(shownSources);

    // make a map of all the backlinks to show for each source
    const blinkMap = newSources.reduce((acc, s) => {
      acc[s.id] = s.backlinks
        .filter((bid) => shownIds.includes(bid))
        .map((bid) => surfaceNoteMap[bid])
        .filter((n) => n && !isAnnote(n));
      return acc;
    }, {} as LinkMap);
    setShownBacklinksMap(blinkMap);
  }, [shownIds, surfaceNoteMap]);

  return (
    <Surface id="surface">
      {/* <div style={{ position: 'relative', top: '24px', paddingLeft: '3.5rem', width: '35rem' }}>
        <CardDropzone linkTo={null} afterId={null} />
        <CardSpacer linkTo={null} afterId={null} />
      </div> */}

      {sources.map((source) => (
        <ColumnWrapper key={source.id}>
          <SourceColumn source={source} shownBacklinks={shownBacklinksMap[source.id] || []} />
        </ColumnWrapper>
      ))}
    </Surface>
  );
};

export default SourceGridSurface;
