import { ChatOpenAI } from '@langchain/openai';
import { AgentStep } from '@langchain/core/agents';
import { supabaseClient } from 'src/db/supabase';

// const DEFAULT_MODEL = 'gpt-3.5-turbo-1106';
// const DEFAULT_MODEL = 'gpt-4o-mini';
const DEFAULT_MODEL = 'gpt-4o';

interface CallbackOptions {
  onToken?: (token: string) => void;
  onStep?: (step: AgentStep) => void;
}

export const getSupabaseToken = async (): Promise<string> => {
  const {
    data: { session },
  } = await supabaseClient.auth.getSession();

  if (!session?.access_token) {
    throw new Error('No authentication token available');
  }

  return session.access_token;
};

export const createLLM = async (
  supabaseAccessToken: string,
  callbacks?: CallbackOptions,
  model: string = DEFAULT_MODEL,
): Promise<ChatOpenAI> => {
  const baseUrl = `${import.meta.env.VITE_SUPABASE_FUNCTIONS_URL}/openai-proxy`;

  return new ChatOpenAI({
    model,
    temperature: 0,
    apiKey: supabaseAccessToken,
    streaming: true,
    callbacks: [
      {
        handleLLMNewToken(token: string) {
          callbacks?.onToken?.(token);
        },
      },
    ],
    configuration: {
      basePath: baseUrl,
      baseOptions: {
        headers: {
          Authorization: `Bearer ${supabaseAccessToken}`,
          'Content-Type': 'application/json',
        },
      },
    },
  });
};

export const createAuthenticatedLLM = async (callbacks?: CallbackOptions): Promise<ChatOpenAI> => {
  const token = await getSupabaseToken();
  return createLLM(token, callbacks);
};
