import { createContext, useContext, ReactNode, useCallback, useState } from 'react';
import { HumanMessage, AIMessage, BaseMessage } from '@langchain/core/messages';
import { AgentExecutor, createOpenAIFunctionsAgent } from 'langchain/agents';

import localPrompt from 'src/db/AnnI/prompt';
import { createTools } from 'src/db/AnnI/tools/index';
import { ChainValues } from '@langchain/core/dist/utils/types';
import { UUID } from 'core/types';
import { AgentStep } from '@langchain/core/agents';
import { createAuthenticatedLLM } from 'src/db/AnnI/llm';
import { useBrainContext } from './BrainContext';
import { ShownIdsContext } from './ShownContext';
import { FocusContext } from './FocusContext';

interface AIContextType {
  chat: (
    thread: UUID,
    message: string,
    callbacks?: {
      onToken?: (token: string) => void;
      onStep?: (step: AgentStep) => void;
    },
  ) => Promise<ChainValues>;
  // Add other AI-related functions as needed
}

const AIContext = createContext<AIContextType | null>(null);

interface AIProviderProps {
  children: ReactNode;
}

export const AIProvider: React.FC<AIProviderProps> = ({ children }) => {
  const [chatHistories, setChatHistories] = useState<Record<UUID, BaseMessage[]>>({});
  const brain = useBrainContext();
  const shown = useContext(ShownIdsContext);
  const { focusedNoteId } = useContext(FocusContext);

  // Create the list of tools available to the AI
  const tools = createTools({ brain, shown, focusedDocumentId: focusedNoteId });

  // test with:
  // Create a note with the title "AI is working in Annote" and some celebratory content
  const chat = useCallback(
    async (
      thread: UUID,
      message: string,
      callbacks?: {
        onToken?: (token: string) => void;
        onStep?: (step: AgentStep) => void;
      },
    ): Promise<ChainValues> => {
      try {
        const llm = await createAuthenticatedLLM(callbacks);

        // Make a functions based agent for proper tool execution with OpenAI
        const agentAnnI = await createOpenAIFunctionsAgent({
          llm,
          tools,
          prompt: localPrompt,
        });

        // The executor is the one that actually runs the agent and returns the result when it's finished
        const agentExecutor = new AgentExecutor({
          agent: agentAnnI,
          tools,
          verbose: false,
          returnIntermediateSteps: true,
          callbacks: [
            {
              handleAgentAction(action) {
                const step: AgentStep = {
                  action,
                  observation: '',
                };
                callbacks?.onStep?.(step);
              },
              handleAgentEnd(output) {
                console.log('Agent End:', output);
                if (output.returnValues?.output) {
                  const step: AgentStep = {
                    action: {
                      tool: 'final_answer',
                      toolInput: output.returnValues.output,
                      log: output.log,
                    },
                    observation: output.returnValues.output,
                  };
                  callbacks?.onStep?.(step);
                }
              },
              // handleChainEnd(outputs) {},
            },
          ],
        });

        // Get the chat history for this thread
        const threadHistory = chatHistories[thread] || [];

        // Run the agent with the message and the thread-specific chat history
        const result = await agentExecutor.invoke({
          input: message,
          chat_history: threadHistory,
        });

        // Update chat history for this specific thread
        setChatHistories((prev) => ({
          ...prev,
          [thread]: [...(prev[thread] || []), new HumanMessage(message), new AIMessage(result.output)],
        }));

        return result;
      } catch (error) {
        console.error('Error in chat:', error);
        throw error;
      }
    },
    [chatHistories, tools, brain, shown],
  );

  return <AIContext.Provider value={{ chat }}>{children}</AIContext.Provider>;
};

export const useAIContext = (): AIContextType => {
  const context = useContext(AIContext);
  if (!context) {
    throw new Error('useAIContext must be used within an AIProvider');
  }
  return context;
};
