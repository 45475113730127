import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { Note } from 'core/types';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { colors } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';

const MenuContainer = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  min-width: 200px;
  z-index: 1000;
`;

const MenuItem = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  padding: 8px 16px;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  color: ${(props) => (props.disabled ? colors.text.placeholder : colors.text.primary)};
  font-size: 14px;
  gap: 12px;

  &:hover {
    background-color: ${(props) => (props.disabled ? 'transparent' : '#F5F5F5')};
  }
`;

interface CardMenuDropdownProps {
  note: Note;
}

const CardMenuDropdown: React.FC<CardMenuDropdownProps> = ({ note }) => {
  const { createTakeaway, deleteNotes } = useBrainContext();
  const { showAbeforeB, showAafterB, hide, shownIds } = useContext(ShownIdsContext);

  const createBacklink = (n: Note): void => {
    const newNote = createTakeaway(n);
    showAbeforeB(newNote.id, n.id);
  };

  const showBacklinks = (n: Note): void => {
    showAafterB(n.backlinks, n.id);
  };

  const showLinks = (n: Note): void => {
    showAbeforeB(n.links, n.id);
  };

  const hideNote = (n: Note): void => {
    hide(n.id);
  };

  const hideOthers = (n: Note): void => {
    hide(shownIds.filter((id) => id !== n.id));
  };

  const deleteNote = (n: Note): void => {
    // TODO: Add confirmation
    hide(n.id);
    deleteNotes([n]);
  };

  const disableHideOthers = useMemo(() => {
    return shownIds.length === 1;
  }, [shownIds]);

  const disableShowLinks = useMemo(() => {
    const hiddenLinks = note.links.filter((id) => !shownIds.includes(id));
    return hiddenLinks.length === 0;
  }, [note.links, shownIds]);

  const disableShowBacklinks = useMemo(() => {
    const hiddenBacklinks = note.backlinks.filter((id) => !shownIds.includes(id));
    return hiddenBacklinks.length === 0;
  }, [note.backlinks, shownIds]);

  console.log('dropdown menu opened', note.id);
  return (
    <MenuContainer>
      <MenuItem onClick={() => createBacklink(note)}>
        <Icon type={IconOption.Plus} size="16px" />
        New backlink note
      </MenuItem>
      <MenuItem disabled={disableShowBacklinks} onClick={() => showBacklinks(note)}>
        <Icon type={IconOption.ShowBacklinks} size="16px" />
        Show all backlinks
      </MenuItem>
      <MenuItem disabled={disableShowLinks} onClick={() => showLinks(note)}>
        <Icon type={IconOption.ShowLinks} size="16px" />
        Show all links
      </MenuItem>
      <MenuItem onClick={() => hideNote(note)}>
        <Icon type={IconOption.ClearBoard} size="16px" />
        Hide this note
      </MenuItem>
      <MenuItem disabled={disableHideOthers} onClick={() => hideOthers(note)}>
        <Icon type={IconOption.ClearBoard} size="16px" />
        Hide all others
      </MenuItem>
      <MenuItem onClick={() => deleteNote(note)}>
        <Icon type={IconOption.Trash} size="16px" />
        Delete
      </MenuItem>
    </MenuContainer>
  );
};

export default CardMenuDropdown;
