// All this here so we can add a "Unlink" button to the edge
// if we don't need the button, remove this custom edge.

import React, { useCallback } from 'react';
import { BaseEdge, EdgeLabelRenderer, getSmoothStepPath, EdgeProps, Edge, Position } from '@xyflow/react';
import { colors, text } from 'core/styles';
import { UUID } from 'core/types';
import styled from 'styled-components';
import { Icon, IconOption } from 'src/components/Icon';

const UnlinkButton = styled.button`
  border: 1px solid ${colors.borders.input.primary};
  border-radius: 4px;
  padding: 1rem 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: ${colors.text.button};
  box-shadow: 0px 3px 6px rgba(15, 15, 15, 0.1);
  line-height: 1.75rem;
  font-size: 1.5rem;
  font-family: ${text.family.primary};
  font-weight: 500;
  background-color: ${colors.bg.primary};
  cursor: pointer;
  z-index: 1011;
  pointer-events: all;

  &:hover {
    box-shadow: 0px 9px 24px rgba(15, 15, 15, 0.2), 0px 3px 6px rgba(15, 15, 15, 0.1);
  }
`;

interface CustomEdgeProps extends EdgeProps {
  id: UUID;
  sourceX: number;
  sourceY: number;
  targetX: number;
  targetY: number;
  selected?: boolean;
  data?: {
    onDelete?: (edges: Edge[]) => void;
  };
}

const ButtonEdge: React.FC<CustomEdgeProps> = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  selected,
  data,
  ...props
}) => {
  const [edgePath, labelX, labelY] = getSmoothStepPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
    borderRadius: 4,
    offset: 16,
    sourcePosition: Position.Left,
    targetPosition: Position.Right,
  });

  const onUnlinkClick = useCallback(
    (event: React.MouseEvent) => {
      event.stopPropagation();
      if (data?.onDelete) {
        data.onDelete([{ id, source: props.source, target: props.target, data }]);
      }
    },
    [id, props.source, props.target, data],
  );

  return (
    <>
      <BaseEdge path={edgePath} {...props} />
      {selected && (
        <EdgeLabelRenderer>
          <div
            style={{
              position: 'absolute',
              transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            }}
          >
            <UnlinkButton onClick={onUnlinkClick}>
              <Icon type={IconOption.Scissors} size="1.375rem" color={colors.text.button} />
              Unlink
            </UnlinkButton>
          </div>
        </EdgeLabelRenderer>
      )}
    </>
  );
};

export const edgeTypes = {
  custom: ButtonEdge,
};
