// A CardSpacer is a component that renders a spacer between two cards - mostly used in the TreeSurface
// It is also a dropzone for new cards to be added in the space.

import React, { useRef, useMemo, useState, useEffect } from 'react';
import styled from 'styled-components';
import { colors } from 'core/styles';
import { getCenter } from 'src/helpers/ports';
import { Line, LineProps } from 'src/routes/Surfaces/Tree/_components/Line';
import ReactDOM from 'react-dom';
import { Note, UUID } from 'core/types';
import { useDragging } from 'src/context/DraggingContext';
import { AddTakeawayButton } from 'src/components/Cards/AddTakeawayButton';

// TODO - the card space here needs to be independant of the card dropzone because of positioning things
// (the drag zone needs to be 100% height of the card tree, but the card spacer needs to come right after the card)

// spacer will need the backlink ref port of it's parent - you can get this from the portRefMap or feed it in cardtree

const Wrapper = styled.div`
  margin: 0px;
  width: 34rem;
  height: 24px;
  padding: 4px;
  display: flex;
  align-items: center;
  /* border: 2px solid black; */
`;
const FakePort = styled.div`
  position: absolute;
  height: 20px;
  width: 20px;
  background-color: transparent;
  margin-left: -12px;
  z-index: 0;
  /* border: 1px solid red; */
`;

const DropBox = styled.div<{ isOver: boolean }>`
  width: 35rem;
  border-radius: 2px;
  border: 1px dashed ${colors.lines.link};

  ${(props) =>
    props.isOver
      ? `height: 20px;
    background-color: ${colors.bg.dropzone};
    border-color: ${colors.lines.selected};`
      : `height: 0px;
    background-color: tranparent;`}
`;

// Render a line to the spacer with a portal to the svg surface
const PortaledLine: React.FC<LineProps> = (props) => {
  const portalId = props.inFront ? 'surface-svg-foreground-portal' : 'surface-svg-background-portal';
  const portalContainer = document.getElementById(portalId);
  if (!portalContainer) {
    return null;
  }
  return ReactDOM.createPortal(<Line {...props} />, portalContainer);
};

export const CardSpacer: React.FC<{
  linkTo: Note | null;
  afterId: UUID | null;
  backlinkPortRef?: React.RefObject<HTMLDivElement>;
}> = ({ linkTo, afterId, backlinkPortRef }) => {
  const [toPos, setToPos] = useState({ x: 0, y: 0 });
  const [fromPos, setFromPos] = useState({ x: 0, y: 0 });
  const { hoveringDropzoneKey, draggingNoteId, isUserDragging } = useDragging();

  const linkPortRef = useRef<HTMLDivElement>(null);

  const isTop = afterId === linkTo?.id;

  useEffect(() => {
    if (!backlinkPortRef?.current || !linkPortRef.current) return;
    const surfaceWrapper = document.getElementById('surface-wrapper');
    if (!surfaceWrapper) return;
    setToPos(getCenter(backlinkPortRef.current, -330, surfaceWrapper));
    setFromPos(getCenter(linkPortRef.current, -330, surfaceWrapper));
  }, [backlinkPortRef, linkPortRef, isUserDragging]);

  // if the after Id is the same as the linkTo, it's a top card
  // const isTop = afterId === linkTo?.id;

  // is Over if the afterId and linkToId's match the global dragging state
  const isOver = useMemo(
    () => hoveringDropzoneKey === `${linkTo?.id}-${afterId}`,
    [hoveringDropzoneKey, linkTo?.id, afterId],
  );
  const dropDisabled = draggingNoteId === linkTo?.id;

  return (
    <Wrapper>
      <FakePort ref={linkPortRef} />
      {!isUserDragging && <AddTakeawayButton linkToNote={linkTo} afterId={afterId || undefined} />}
      {isUserDragging && !dropDisabled && <DropBox isOver={isOver}></DropBox>}
      {isUserDragging && fromPos && toPos && (
        <PortaledLine
          inFront={isOver}
          kind={isTop ? 'graph' : 'tree'}
          fromPos={fromPos}
          toPos={toPos}
          key={`drag-line-${linkTo?.id}-${afterId}`}
          hoverHeight={1000}
        />
      )}
    </Wrapper>
  );
};
