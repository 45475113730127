import React from 'react';
import styled from 'styled-components';
import { colors } from 'core/styles';
import { Icon, IconOption } from 'src/components/Icon';
import { CardSize } from 'src/types';

const SizeMenuWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: ${colors.bg.primary};
  box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.12), 0px 1px 2px 0px rgba(15, 15, 15, 0.16);
  border-radius: 2px;
  margin-top: 8px;
  margin-left: 8px;
  z-index: 2;
  display: none;

  // will be shown when it's parent card is hovered
  .card:hover & {
    display: flex;
  }
`;

const Button = styled.button<{ isActive: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  background: ${({ isActive }) => (isActive ? '#E9E9E8' : 'none')};
  &&:hover {
    background: #dbdbda;
  }
`;

const sizeConfig: Record<CardSize, { icon: IconOption; label: string }> = {
  SMALL: { icon: IconOption.MenuClosed, label: 'Compact view' },
  MEDIUM: { icon: IconOption.MenuOpenA, label: 'Medium view' },
  LARGE: { icon: IconOption.MenuOpenB, label: 'Full view' },
};

interface SizeButtonProps {
  size: CardSize;
  currentSize: CardSize;
  onClick: (size: CardSize) => void;
}

const SizeButton: React.FC<SizeButtonProps> = ({ size, currentSize, onClick }) => {
  const config = sizeConfig[size];
  return (
    <Button
      isActive={currentSize === size}
      onClick={(e: React.MouseEvent) => {
        e.stopPropagation();
        onClick(size);
      }}
      title={config.label}
    >
      <Icon type={config.icon} size="1.25rem" />
    </Button>
  );
};

interface SizeMenuProps {
  currentSize: CardSize;
  onChange: (size: CardSize) => void;
  inPreview?: boolean;
  sizeOptions?: CardSize[];
}

export const SizeMenu: React.FC<SizeMenuProps> = ({
  currentSize,
  onChange,
  inPreview = false,
  sizeOptions = ['SMALL', 'MEDIUM', 'LARGE'],
}) => {
  if (inPreview) return null;

  return (
    <SizeMenuWrapper>
      {sizeOptions.map((size) => (
        <SizeButton key={size} size={size} currentSize={currentSize} onClick={onChange} />
      ))}
    </SizeMenuWrapper>
  );
};
