import React, { useContext, useMemo, useRef, useEffect, useState } from 'react';
import styled from 'styled-components';

import { Card } from 'src/components/Cards/Card';
import { Note, isAnnote, isSource } from 'core/types';
import HiddenCards from 'src/components/Cards/HiddenCard';
import { ShownIdsContext } from 'src/context/ShownContext';
import { CardHandleWrapper } from './Handle';

const CardWrapper = styled.div`
  position: relative;
  height: fit-content;
  width: fit-content;
  /* min-width: 100%; */
  max-width: none;
  /* border: 1px solid green; */
  &:hover {
    z-index: 1001;
  }
`;

export const FlowCard: React.FC<{
  data: {
    note: Note;
    onResize: (id: string, width: number, height: number) => void;
  };
}> = ({ data }) => {
  const { shownIds, surfaceNoteMap } = useContext(ShownIdsContext);
  const nodeRef = useRef<HTMLDivElement>(null);
  const [prevDimensions, setPrevDimensions] = useState({ width: 0, height: 0 });

  const { note, onResize } = data;

  const hiddenBacklinks = useMemo(() => {
    if (!note) return [];

    const allBacklinks = note.backlinks.map((id) => surfaceNoteMap[id]).filter((n) => n);

    const backlinkIds = allBacklinks.filter((bl) => !isAnnote(bl)).map((bl) => bl.id);
    if (isSource(note)) {
      // rollup annote backlinks
      const annotes = allBacklinks.filter((bl) => isAnnote(bl));
      backlinkIds.push(...annotes.flatMap((a) => a.backlinks));
    }

    const hiddenIds = new Set(backlinkIds.filter((id) => !shownIds.includes(id)));
    return Array.from(hiddenIds)
      .map((id) => surfaceNoteMap[id])
      .filter((n) => n);
  }, [shownIds, surfaceNoteMap, note]);

  const hiddenLinks = useMemo(() => {
    if (!note) return [];

    const allLinks = note.links.map((id) => surfaceNoteMap[id]).filter((n) => n);
    const linkIds = allLinks.filter((l) => !isAnnote(l)).map((l) => l.id);

    if (isSource(note)) {
      // rollup annote links
      const annotes = allLinks.filter((l) => isAnnote(l));
      linkIds.push(...annotes.flatMap((a) => a.links));
    }
    const hiddenIds = new Set(linkIds.filter((id) => !shownIds.includes(id)));
    return Array.from(hiddenIds)
      .map((id) => surfaceNoteMap[id])
      .filter((n) => n);
  }, [shownIds, surfaceNoteMap, note]);

  // Observer that calls onResize when the node changes size
  useEffect(() => {
    if (!nodeRef.current || !onResize) return () => {};
    if (!note) return () => {};
    const resizeObserver = new ResizeObserver((entries) => {
      entries.forEach((entry) => {
        const { width, height } = entry.contentRect;
        if (width !== prevDimensions.width || height !== prevDimensions.height) {
          onResize(note.id, width, height);
          setPrevDimensions({ width, height });
        }
      });
    });

    resizeObserver.observe(nodeRef.current);

    return () => {
      resizeObserver.disconnect();
    };
  }, [onResize, note, nodeRef, prevDimensions]);

  if (!note) return null;
  return (
    <CardWrapper ref={nodeRef}>
      <HiddenCards kind="backlinks" hiddenNotes={hiddenBacklinks} note={note} />
      <HiddenCards kind="links" hiddenNotes={hiddenLinks} note={note} />
      <CardHandleWrapper noteId={note.id}>
        <Card key={note.id} note={note}></Card>
      </CardHandleWrapper>
    </CardWrapper>
  );
};
