import React, { useCallback, useEffect, useRef, useContext, useState } from 'react';
import styled from 'styled-components';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';
import { useDrop } from 'react-dnd';
import { Note, isSource, isAnnote, UUID } from 'core/types';
import { debounce, DebouncedFunc } from 'lodash';
import {
  ReactFlow,
  useNodesState,
  useEdgesState,
  Controls,
  type Node,
  type OnConnectStartParams,
  Edge,
  Connection,
  OnEdgesDelete,
  useReactFlow,
  OnConnectEnd,
  NodeOrigin,
  SelectionMode,
  ReactFlowProvider,
  useViewport,
  OnNodesDelete,
} from '@xyflow/react';
import { colors } from 'core/styles';
import { createNote } from 'core/utils/notes';
import { useDragging } from 'src/context/DraggingContext';
import { FocusContext } from 'src/context/FocusContext';
import { FlowCard } from './_components/FlowCard';
import '@xyflow/react/dist/style.css';
import { AutoArrangeButton } from './_components/ArrangeControl';
import { edgeTypes } from './_components/Edge';

export type ArrangeFunction = (
  nodes: Node[],
  edges: Edge[],
  fixedNodeIds?: string[],
) => { nodes: Node[]; edges: Edge[] };

export interface Link {
  from: string;
  to: string;
  annoteFrom?: string; // the true fromId if an annote
}

export type NodeData = {
  note: Note;
  onResize: (id: string, width: number, height: number) => void;
};

const nodeOrigin: NodeOrigin = [0.5, 0];

const nodeTypes = {
  card: FlowCard,
};

// 1 - left mouse button
// 2 - right mouse button
const panOnDrag = [1, 2];

const Surface = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-size: 10px 10px;
  background-position: 0 0;
  overflow: auto;

  & .react-flow__edge-path {
    stroke: ${colors.lines.flowLink} !important;
    stroke-width: 1.5;
  }

  & .selected .react-flow__edge-path {
    stroke: ${colors.lines.selected} !important;
  }
`;

// TODO - set this programatically based on the actual node
const DEFAULT_NODE_WIDTH = 35 * 8;
const DEFAULT_NODE_HEIGHT = 168;

// Create a new context

// Add this with your other type definitions
type CustomEdgeData = {
  annoteFrom?: string;
};

// Update the Edge type to include the data property
type CustomEdge = Edge<CustomEdgeData>;

const FlowSurfaceContent: React.FC<{ arrangeFunction: ArrangeFunction }> = ({ arrangeFunction }) => {
  const { shownIds, surfaceNoteMap } = useContext(ShownIdsContext);
  const [nodes, setNodes, onNodesChange] = useNodesState<Node>([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState<CustomEdge>([]);
  const { linkNotes, unlinkNotes, updateNotes } = useBrainContext();
  const { showAafterB, showAbeforeB, hide, show } = useContext(ShownIdsContext);
  const { screenToFlowPosition } = useReactFlow();
  const reactFlowWrapper = useRef<HTMLDivElement>(null);
  const { setDraggingConnectionId, setDraggingConnectionType, setIsOverLibrary } = useDragging();
  const reactFlowInstance = useReactFlow();
  const { x: viewportX, zoom } = useViewport();
  const { setDraggingNoteId } = useDragging();
  const { focusedNoteId } = useContext(FocusContext);

  // These are nodes who's positions should not be changed by auto-arrange
  const [fixedNodeIds, setFixedNodeIds] = useState<string[]>([]);

  const nodesRef = useRef(nodes);
  const edgesRef = useRef(edges);

  // Ensure all shown Annotes also have their source shown.
  useEffect(() => {
    const shownAnnotes = shownIds.map((id) => surfaceNoteMap[id]).filter((n) => n && isAnnote(n));
    const unshownSources: string[] = [];
    shownAnnotes.forEach((annote) => {
      if (!shownIds.includes(annote.createdFromId)) {
        unshownSources.push(annote.createdFromId);
      }
    });
    if (unshownSources.length) {
      show(unshownSources);
    }
  }, [shownIds, surfaceNoteMap]);

  // Accept dropped cards from the library
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [{ isOver }, dropRef] = useDrop<Note, void, { isOver: boolean }>({
    accept: ['CARD', 'SMALLCARD'],
    drop: (item, monitor) => {
      if (monitor.didDrop()) return; // the item was dropped in a nested spot
      show(item.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
  });

  useEffect(() => {
    // This is a workaround to get the latest nodes in handleAutowithout causing an infinite re-render loop
    // console.log('nodes updated', nodes);
    nodesRef.current = nodes;
  }, [nodes]);

  useEffect(() => {
    // This is a workaround to get the latest nodes in handleAutowithout causing an infinite re-render loop
    edgesRef.current = edges;
  }, [edges]);

  const handleAutoArrange = useCallback(() => {
    const { nodes: arrangedNodes, edges: arrangedEdges } = arrangeFunction(
      nodesRef.current,
      edgesRef.current,
      fixedNodeIds,
    );
    setNodes(arrangedNodes);
    setEdges(arrangedEdges);
  }, [arrangeFunction, setNodes, setEdges, fixedNodeIds]);

  const refitView = useCallback(
    (focusNodeIds?: string[]) => {
      setTimeout(() => {
        if (focusNodeIds && focusNodeIds.length > 0) {
          reactFlowInstance.fitView({
            padding: 0.4, // how much space to leave around the nodes
            duration: 200,
            nodes: focusNodeIds.map((id) => ({ id })),
          });
        } else {
          reactFlowInstance.fitView({ padding: 0.2, duration: 200 });
        }
      }, 100);
    },
    [reactFlowInstance],
  );

  const debouncedArrangeAndRefitRef = useRef<DebouncedFunc<(focusNodeIds?: string[]) => void> | null>(null);

  useEffect(() => {
    debouncedArrangeAndRefitRef.current = debounce((focusNodeIds?: string[]) => {
      handleAutoArrange();
      refitView(focusNodeIds);
    }, 300);

    return () => {
      if (debouncedArrangeAndRefitRef.current) {
        debouncedArrangeAndRefitRef.current.cancel();
      }
    };
  }, [handleAutoArrange, refitView]);

  const debouncedArrange = useCallback((focusNodeIds?: string[]) => {
    if (debouncedArrangeAndRefitRef.current) {
      debouncedArrangeAndRefitRef.current(focusNodeIds);
    }
  }, []);

  // When a node resizes here is how we handle
  // TODO we should probably use node.measured.height and width?  it's built in.
  const handleNodeResize = useCallback(
    (id: string, width: number, height: number) => {
      setNodes((prevNodes) => prevNodes.map((node) => (node.id === id ? { ...node, width, height } : node)));

      // if the width is > 700 focus the view on that node
      debouncedArrange(width > 700 ? [id] : undefined);
    },
    [setNodes, debouncedArrange, reactFlowInstance],
  );

  const onPaneDoubleClick = useCallback(
    (event: React.MouseEvent) => {
      // Prevent the default behavior
      event.preventDefault();
      // Get the position of the double click in flow coordinates
      const position = screenToFlowPosition({ x: event.clientX, y: event.clientY });
      // Create a new note
      const newNote = createNote('', 'takeaway', '');

      // Create a new node
      const newNode: Node = {
        id: newNote.id,
        type: 'card',
        position,
        data: { note: newNote, onResize: handleNodeResize },
      };

      // keep this node's position fixed
      setFixedNodeIds((nids) => [...nids, newNote.id]);
      // Add the new node to the existing nodes
      setNodes((nds) => nds.concat(newNode));
      updateNotes([newNote]);
      show(newNote.id);

      // remove the node from the fixedNodeIds after a short delay
      setTimeout(() => {
        setFixedNodeIds((nids) => nids.filter((id) => id !== newNote.id));
      }, 10000);
    },
    [screenToFlowPosition, setNodes],
  );

  // Connecting two notes
  const onConnect = useCallback(
    (params: Connection) => {
      const { source: fromId, target: toId } = params;
      if (!fromId || !toId) return;
      linkNotes(surfaceNoteMap[fromId], surfaceNoteMap[toId]);
    },
    [linkNotes, surfaceNoteMap],
  );

  // Create a new note and link it if an edge is dropped on an empty space
  const onConnectEnd: OnConnectEnd = useCallback(
    async (event: MouseEvent | TouchEvent, connectionState) => {
      setDraggingConnectionId(null);
      setDraggingConnectionType(null);
      // when a connection is dropped on the pane it's not valid
      if (!connectionState.isValid) {
        // we need to remove the wrapper bounds, in order to get the correct position

        const newNote = createNote('', 'takeaway', '', connectionState?.fromNode?.id);
        const fromId = connectionState?.fromNode?.id;
        if (!fromId) return;
        const { clientX, clientY } = 'changedTouches' in event ? event.changedTouches[0] : event;

        const newNode: Node = {
          id: newNote.id,
          type: 'card',
          position: screenToFlowPosition({
            x: clientX,
            y: clientY,
          }),
          data: { note: newNote, onResize: handleNodeResize },
          origin: [0.5, 0.0],
        };
        setNodes((nds) => nds.concat(newNode));
        if (connectionState.fromHandle?.type === 'source') {
          await linkNotes(surfaceNoteMap[fromId], newNote);
          await showAbeforeB(newNote.id, fromId);
        } else {
          await linkNotes(newNote, surfaceNoteMap[fromId]);
          await showAafterB(newNote.id, fromId);
        }
      }
    },
    [screenToFlowPosition, linkNotes, surfaceNoteMap, showAafterB, showAbeforeB, setNodes],
  );

  const onEdgesDelete: OnEdgesDelete = useCallback(
    (edgesToDelete) => {
      edgesToDelete.forEach((edge) => {
        // If the linking note is an annote, we need to use it's fromId
        const fromId = edge.data?.annoteFrom || edge.source;
        const toId = edge.target;
        const fromNote = surfaceNoteMap[fromId as UUID];
        const toNote = surfaceNoteMap[toId as UUID];
        if (fromNote && toNote) {
          unlinkNotes(fromNote, toNote);
        }
      });
    },
    [unlinkNotes, surfaceNoteMap],
  );

  useEffect(() => {
    console.log('auto arrange remade');
  }, [handleAutoArrange]);

  // Sync the Nodes with shownIds and updates to surfaceNoteMap
  useEffect(() => {
    const shownNotes = shownIds.map((id) => surfaceNoteMap[id]).filter((note) => note && note.type !== 'annote');

    // first populate the new Node list with existing nodes
    const newNodes = nodes
      .filter((node) => shownIds.includes(node.id))
      .map((node) => ({
        ...node,
        data: {
          ...node.data,
          // ensure the note and onResize are the latest
          note: surfaceNoteMap[node.id],
          onResize: handleNodeResize,
        } as NodeData,
      })) as Node[];
    // if there are new shownIds (that aren't annotes), add them to the nodes
    const nodeIds = new Set(newNodes.map((node) => node.id));
    const notesWithoutNodes = shownNotes.filter((n) => n.type !== 'annote' && !nodeIds.has(n.id));
    notesWithoutNodes.forEach((note) => {
      newNodes.push({
        id: note.id,
        type: 'card',
        position: { x: 300, y: 300 }, // Initial position, will be updated later
        width: DEFAULT_NODE_WIDTH,
        height: DEFAULT_NODE_HEIGHT,
        data: { note, onResize: handleNodeResize } as NodeData,
      } as Node);
    });

    const allNodeIds = new Set(newNodes.map((node) => node.id));
    const shownLinks = newNodes.reduce<Link[]>((acc, node) => {
      const note = surfaceNoteMap[node.id];
      if (!note) return acc;
      const linksToShownNodes = note.links.filter((id) => allNodeIds.has(id));
      linksToShownNodes.forEach((linkId) => {
        acc.push({ from: node.id, to: linkId });
      });

      // rollup annote links to sources
      if (isSource(note)) {
        const annotes = note.links.map((id) => surfaceNoteMap[id]).filter((n) => n && isAnnote(n));
        annotes.forEach((annote) => {
          const shownLinksFromAnnote = annote.links.filter((id) => allNodeIds.has(id));
          acc.push(...shownLinksFromAnnote.map((linkId) => ({ from: node.id, to: linkId, annoteFrom: annote.id })));
        });
      }
      // don't show links to yourself
      return acc.filter((link) => link.from !== link.to);
    }, []);

    const newEdges: Edge[] = shownLinks.map((link) => ({
      id: `link-${link.from}-${link.to}`,
      source: link.from,
      target: link.to,
      type: 'custom',
      curvature: -0.5,
      data: { onDelete: onEdgesDelete, annoteFrom: link.annoteFrom },
    }));

    setNodes(newNodes);
    setEdges(newEdges);
  }, [shownIds, surfaceNoteMap, setNodes, setEdges, handleNodeResize]);

  const onNodeDragStart = useCallback((event: React.MouseEvent, node: Node) => {
    setDraggingNoteId(node.id); // dragging lib only takes one right now.
    setIsOverLibrary(true); // we're just turning on the hover state for the library for any dragging now
  }, []);

  // const onNodeDrag = useCallback((event: React.MouseEvent, node: Node, nodes: Node[]) => {}, []);

  const onNodeDragStop = useCallback(
    (event: React.MouseEvent, node: Node, draggedNodes: Node[]) => {
      setDraggingNoteId(null);
      setIsOverLibrary(false);

      // if the node is dragged out of the viewport, set isOverLibrary to truec
      const flowBounds = reactFlowInstance.getViewport();

      const isOutsideLeft = (n: Node): boolean => {
        const nodeLeft = n.position.x * zoom + viewportX;
        return nodeLeft < flowBounds.x;
      };

      if (draggedNodes.some(isOutsideLeft)) {
        draggedNodes.forEach((n) => hide(n.id));
      }
    },
    [zoom, viewportX, reactFlowInstance, hide],
  );
  useEffect(() => {
    if (!focusedNoteId) return;

    const node = reactFlowInstance.getNode(focusedNoteId);
    if (!node) return;

    const { x, y, zoom: viewportZoom } = reactFlowInstance.getViewport();
    const reactFlowBounds = reactFlowWrapper.current?.getBoundingClientRect();

    if (!reactFlowBounds) return;

    const isNodeInView = (nodeToCheck: Node): boolean => {
      const nodeLeft = nodeToCheck.position.x * viewportZoom + x;
      const nodeTop = nodeToCheck.position.y * viewportZoom + y;
      const nodeRight = nodeLeft + (nodeToCheck.width || 0) * viewportZoom;
      const nodeBottom = nodeTop + (nodeToCheck.height || 0) * viewportZoom;

      return (
        nodeLeft >= 0 && nodeTop >= 0 && nodeRight <= reactFlowBounds.width && nodeBottom <= reactFlowBounds.height
      );
    };

    if (!isNodeInView(node)) {
      refitView([focusedNoteId]);
    }
  }, [focusedNoteId, refitView, reactFlowInstance]);

  useEffect(() => {
    console.log('react flow instance updated', reactFlowInstance);
  }, [reactFlowInstance]);

  const onNodesDelete: OnNodesDelete = useCallback(
    (nodesToDelete) => {
      hide(nodesToDelete.map((node) => node.id));
      // Prevent the default deletion behavior
      return false;
    },
    [hide],
  );

  // Workaround for double click to add note
  const lastClickTime = useRef<number>(0);
  const doubleClickDelay = 300; // milliseconds

  const handlePaneClick = useCallback(
    (event: React.MouseEvent) => {
      const currentTime = new Date().getTime();
      const timeSinceLastClick = currentTime - lastClickTime.current;
      if (timeSinceLastClick < doubleClickDelay) {
        onPaneDoubleClick(event);
      }

      lastClickTime.current = currentTime;
    },
    [onPaneDoubleClick],
  );

  return (
    <div ref={dropRef}>
      <Surface id="surface" ref={reactFlowWrapper}>
        <ReactFlow
          // defaultViewport={{ x: 100, y: 0, zoom: 0.5 }} // Adjust the zoom value as needed
          nodes={nodes}
          edges={edges}
          minZoom={0.05}
          nodeTypes={nodeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onConnectStart={(event: MouseEvent | TouchEvent, params: OnConnectStartParams) => {
            setDraggingConnectionId(params.nodeId);
            setDraggingConnectionType(params.handleType);
          }}
          onEdgesDelete={onEdgesDelete}
          fitView
          onConnectEnd={onConnectEnd}
          nodeOrigin={nodeOrigin}
          // translateExtent={translateExtent}
          // panning options
          panOnScroll={true}
          selectionOnDrag={true}
          panOnDrag={panOnDrag}
          selectionMode={SelectionMode.Partial}
          onPaneClick={handlePaneClick}
          proOptions={{ hideAttribution: true }}
          onNodeDragStart={onNodeDragStart}
          // onNodeDrag={onNodeDrag}
          onNodeDragStop={onNodeDragStop}
          selectNodesOnDrag={false}
          onNodesDelete={onNodesDelete}
          zoomOnDoubleClick={false}
          edgeTypes={edgeTypes}
        >
          <Controls position="bottom-right" showInteractive={false}>
            <AutoArrangeButton onArrange={debouncedArrange} />
          </Controls>
        </ReactFlow>
      </Surface>
    </div>
  );
};

const FlowSurface: React.FC<{ arrangeFunction: ArrangeFunction }> = ({ arrangeFunction }) => {
  return (
    <ReactFlowProvider>
      <FlowSurfaceContent arrangeFunction={arrangeFunction} />
    </ReactFlowProvider>
  );
};

export default FlowSurface;
