import { Source, Annote } from 'core/types';
import { ChatPromptTemplate, HumanMessagePromptTemplate, SystemMessagePromptTemplate } from '@langchain/core/prompts';
import { createAuthenticatedLLM } from '../llm';

export const extractFactualNotes = async (
  source: Source,
  highlights: Annote[],
  noteCount: number = 5,
): Promise<{ title: string; content: string }[]> => {
  console.log('inside extractFactualNotes');

  try {
    const llm = await createAuthenticatedLLM();
    console.log('llm created', llm);

    const promptTemplate = ChatPromptTemplate.fromMessages([
      SystemMessagePromptTemplate.fromTemplate(
        `You are a diligent researcher extracting factual information from documents.
        Your task is to create specific, factual notes focusing only on concrete information.
        You must respond with only a valid JSON array, with no additional text, formatting, or markdown code blocks.`,
      ),
      HumanMessagePromptTemplate.fromTemplate(
        `Create {noteCount} distinct, factual notes from the following content and its annotated highlights.
        Focus only on extracting specific facts, data, and direct information.
        Do not include interpretations, lessons, or abstract principles.

        Source Title: {title}
        Source Content: {content}

        Annotated Highlights:
        {highlights}

        Each highlight above contains:
        - The highlighted text from the source (marked with "> ")
        - The reader's annotation about that text (if present, on the line after)

        RESPONSE FORMAT:
        Respond with only a JSON array. Each object must have:
        1. "title": A clear, specific title describing the fact
        2. "content": The factual content in markdown format

        DO NOT include any explanation text or code block formatting.
        DO NOT wrap the JSON in backticks or markdown.
        ONLY return the raw JSON array.`,
      ),
    ]);

    console.log('promptTemplate created');

    // Format highlights for the prompt
    const highlightsText = highlights.map((h) => `> ${h.value}\n${h.title || ''}`).join('\n\n');
    console.log('highlightsText created');

    try {
      const formattedPrompt = await promptTemplate.format({
        noteCount,
        title: source.title,
        content: source.value,
        highlights: highlightsText,
      });
      console.log('formatted prompt created');

      try {
        console.log('calling llm.invoke...');
        const response = await llm.invoke(formattedPrompt);
        console.log('got raw response from llm:', response.content);

        // Clean the response string before parsing
        const cleanedContent = response.content
          .toString()
          .trim()
          .replace(/^```json\s*/, '') // Remove leading JSON code block marker
          .replace(/\s*```$/, '') // Remove trailing code block marker
          .replace(/^`|`$/g, ''); // Remove any surrounding backticks

        try {
          const parsed = JSON.parse(cleanedContent);
          console.log('successfully parsed response');
          return parsed;
        } catch (parseError) {
          console.error('Failed to parse LLM response:', parseError);
          console.log('Raw response content:', response.content);
          console.log('Cleaned content:', cleanedContent);
          throw new Error('Failed to parse factual notes from LLM response');
        }
      } catch (llmError) {
        console.error('LLM invoke failed:', llmError);
        throw new Error('Failed to get response from LLM');
      }
    } catch (formatError) {
      console.error('Prompt formatting failed:', formatError);
      throw new Error('Failed to format prompt');
    }
  } catch (error) {
    console.error('Top level error in extractFactualNotes:', error);
    throw error;
  }
};
