import React, { useContext } from 'react';
import styled from 'styled-components';
import { Icon, IconOption } from 'src/components/Icon';
import { Note } from 'core/types';
import { colors } from 'core/styles';
import { useBrainContext } from 'src/context/BrainContext';
import { ShownIdsContext } from 'src/context/ShownContext';

interface SuggestionHandlerProps {
  children: React.ReactNode;
  note: Note;
}

const SuggestionWrapper = styled.div<{ isSuggestion: boolean }>`
  position: relative;
  ${({ isSuggestion }) =>
    isSuggestion &&
    `
    border: 2px solid ${colors.text.accept};
    border-radius: 4px;

    .reject:hover ~ & {
      border-color: ${colors.text.reject};
    }
  `}
`;

const ActionButtons = styled.div`
  position: absolute;
  top: -26px;
  right: 2px;
  display: flex;
  /* gap: 8px; */
`;

const ActionButton = styled.button`
  background: ${colors.bg.primary};
  border: 0px;
  /* border: 1px solid ${colors.borders.input.primary}; */
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;

  &.accept {
    color: ${colors.text.accept};
    background-color: ${colors.text.accept};
    &:hover {
      border-color: ${colors.text.accept};
    }
  }

  &.reject {
    color: ${colors.text.reject};
    background-color: ${colors.text.reject};
    &:hover {
      border-color: ${colors.text.reject};
    }
  }
`;

export const SuggestionHandler: React.FC<SuggestionHandlerProps> = ({ children, note }) => {
  if (!note.isSuggestion) {
    return <>{children}</>;
  }

  const { updateNotes, deleteNotes } = useBrainContext();
  const { hide } = useContext(ShownIdsContext);
  const { getNoteMapByIds } = useBrainContext();

  const handleAccept = async (): Promise<void> => {
    const acceptedNote = { ...note, isSuggestion: undefined };

    // update linked notes to include this note's backlink
    const linkedNotesMap = note.links.length > 0 ? await getNoteMapByIds(note.links) : {};
    const linkedNotes = Object.values(linkedNotesMap);
    linkedNotes.forEach((linkedNote) => {
      if (!linkedNote.backlinks.includes(note.id)) {
        linkedNote.backlinks.push(note.id);
      }
    });

    console.log('acceptedNote', acceptedNote);
    updateNotes([acceptedNote, ...linkedNotes]);
  };

  const handleReject = (): void => {
    console.log('Reject suggestion', note.id);
    hide(note.id);
    deleteNotes([note]);
  };

  return (
    <SuggestionWrapper isSuggestion={note.isSuggestion}>
      <ActionButtons>
        <ActionButton className="accept" onClick={handleAccept} title="Accept suggestion">
          <Icon type={IconOption.Check} size="16px" />
        </ActionButton>
        <ActionButton className="reject" onClick={handleReject} title="Reject suggestion">
          <Icon type={IconOption.Close} size="16px" />
        </ActionButton>
      </ActionButtons>
      {children}
    </SuggestionWrapper>
  );
};
