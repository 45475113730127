import React, { useEffect, useState, useContext } from 'react';
// This surface lays out the notes passed to it in shownIds in a tree-like structure

import styled from 'styled-components';
import { NoteMap, UUID, isAnnote, isSource, Annote } from 'core/types';
import { ShownIdsContext } from 'src/context/ShownContext';
import { CardDropzone } from 'src/components/Cards/CardDropzone';
import { CardSpacer } from 'src/routes/Surfaces/Tree/_components/CardSpacer';
import { ShownTree } from './types';
import { CardTree } from './_components/CardTree';
import { Links } from './_components/Link';

interface AnnotesMap {
  [key: UUID]: Annote[];
}

const Surface = styled.div`
  position: relative; /* Ensure it's positioned relative to its container */
  display: flex;
  flex-direction: column;
  width: fit-content; /* Ensure Surface takes the full width */
  gap: 24px;
  padding: 1rem 20rem 10rem 10rem; /* Add padding to the top and bottom */

  pointer-events: auto;
`;

// This function builds the tree structure of how the shown cards will be displayed
const buildShownTree = (
  shownIds: string[],
  notes: NoteMap,
  showAbeforeB: (a: UUID | UUID[], b: UUID) => void,
): ShownTree => {
  const result: ShownTree = {
    rootNotes: [],
    shownBranches: {},
    hiddenLinkNotes: {},
    hiddenBacklinkNotes: {},
    shownLinksInfo: [],
  };

  // Take any annote Id's and swap them for the source Id's
  const swapAnnoteForSourceIds = (ids: UUID[]): UUID[] => {
    return Array.from(
      new Set(
        ids.reduce((acc: UUID[], id) => {
          const n = notes[id];
          if (n?.type === 'annote' && n.createdFromId) {
            const sourceId = n.createdFromId;
            if (!acc.includes(sourceId)) {
              acc.push(sourceId);
            }
          } else if (!acc.includes(id)) {
            acc.push(id);
          }
          return acc;
        }, []),
      ),
    );
  };

  const shownNoteMap = shownIds.reduce((acc, id) => {
    const n = notes[id];
    if (n) acc[id] = n;
    return acc;
  }, {} as NoteMap);

  // ensure that each shown annote also has a source shown too
  let isShowingMore = false;
  const annotes = Object.values(notes).filter(isAnnote);
  annotes.forEach((annote) => {
    if (shownIds.includes(annote.id) && !shownIds.includes(annote.createdFromId)) {
      showAbeforeB(annote.createdFromId, annote.id);
      isShowingMore = true; // we need to wait for the source to be shown
    }
  });
  if (isShowingMore) return result; // saves some compute if we don't have all our notes ready

  const annotesByCreatorMap = annotes.reduce((acc, annote) => {
    if (!acc[annote.createdFromId]) acc[annote.createdFromId] = [];
    acc[annote.createdFromId].push(annote);
    return acc;
  }, {} as AnnotesMap);

  // Make shownBranches - a map of all root nodes and their Source or Takeaway children to draw
  const swappedShownIds = swapAnnoteForSourceIds(shownIds);
  swappedShownIds.forEach((id) => {
    const shownNote = shownNoteMap[id];
    if (!shownNote) return;

    let { links, backlinks } = shownNote;
    if (isSource(shownNote)) {
      const sourceAnnotes = annotesByCreatorMap[shownNote.id] || [];
      console.log('sourceAnnotes for ', shownNote.id, sourceAnnotes);
      if (sourceAnnotes.length > 0) {
        const extraLinks = sourceAnnotes?.flatMap((a) => a.links) || [];
        // add in the links from annotes
        links = Array.from(new Set([...links, ...extraLinks]));
        // remove anote ids
        links = links.filter((lid) => !sourceAnnotes.map((a) => a.id).includes(lid));
        const extraBacklinks = sourceAnnotes?.flatMap((a) => a.backlinks) || [];
        backlinks = Array.from(new Set([...backlinks, ...extraBacklinks]));
      }
    } else {
      // if it's an annote, swap it for the source
      links = swapAnnoteForSourceIds(links);
      backlinks = swapAnnoteForSourceIds(backlinks);
    }
    // store the hidden links and backlinks
    result.hiddenLinkNotes[shownNote.id] = links
      .filter((lid) => !shownIds.includes(lid))
      .map((lid) => notes[lid])
      .filter((n) => n)
      .filter((n) => !isAnnote(n));
    result.hiddenBacklinkNotes[shownNote.id] = backlinks
      .filter((bid) => !shownIds.includes(bid))
      .map((bid) => notes[bid])
      .filter((n) => n)
      .filter((n) => !isAnnote(n));

    // Are any of this notes links already shown?  If so put it in it's branch
    const linkedEarlier = Object.keys(result.shownBranches)
      .filter((earlierId) => links.includes(earlierId))
      .flat();
    if (linkedEarlier.length > 0) {
      // if the note links to an earlier note put it in it's branch
      // Here we link it to the LAST earlier shown note which will be closer to where the user has moved a note
      result.shownBranches[linkedEarlier[linkedEarlier.length - 1]].push(shownNote);
    } else {
      // otherwise start a new root node
      result.rootNotes.push(shownNote);
    }
    result.shownBranches[shownNote.id] = [];

    // Build the info for the links to be shown
    const shownLinkNotes = links.map((linkId) => shownNoteMap[linkId]).filter((n) => n);
    shownLinkNotes.forEach((toNote) => {
      result.shownLinksInfo.push({
        fromNote: shownNote,
        toNote,
        kind: result.shownBranches[toNote.id]?.includes(shownNote) ? 'tree' : 'graph',
      });
    });
  });

  return result;
};

const TreeSurface: React.FC = () => {
  const { shownIds, showAbeforeB, surfaceNoteMap } = useContext(ShownIdsContext);
  const [shownTree, setShownTree] = useState<ShownTree>({
    rootNotes: [],
    shownBranches: {},
    hiddenLinkNotes: {},
    hiddenBacklinkNotes: {},
    shownLinksInfo: [],
  });

  const [portRefMap, setPortRefMap] = React.useState<{ [key: string]: React.RefObject<HTMLDivElement> }>({});

  useEffect(() => {
    const newShownTree = buildShownTree(shownIds, surfaceNoteMap, showAbeforeB);
    setShownTree(newShownTree);
  }, [shownIds, surfaceNoteMap, showAbeforeB]);

  return (
    <Surface id="surface">
      <div style={{ position: 'relative', top: '24px', paddingLeft: '3.5rem', width: '35rem' }}>
        <CardDropzone linkTo={null} afterId={null} />

        <CardSpacer linkTo={null} afterId={null} />
      </div>
      {shownTree.rootNotes.map((note) => (
        <CardTree key={note.id} note={note} shownTree={shownTree} setPortRefMap={setPortRefMap} />
      ))}
      <Links shownLinksInfo={shownTree.shownLinksInfo} portRefMap={portRefMap} />
    </Surface>
  );
};

export default TreeSurface;
